import React, { useEffect, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import Router from 'next/router';
import Link from 'next/link';
import { signIn } from 'next-auth/react';

import InputMask from 'react-input-mask';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';
import { VisibilityOff, Visibility } from '@mui/icons-material';

import WebApi from 'shared/services';
import { schema, defaultValue } from './model';
import style from './style.module.scss';
import theme from 'shared/theme';

export const UI = ({
  handleClose,
  onChangeStep,
}: {
  handleClose?: Function;
  onChangeStep?: Function;
}) => {
  const [successSignUp, setSuccessSignUp] = useState(false);
  const [successSignIn, setSuccessSignIn] = useState(false);
  const [showPass, setShowPas] = useState(false);
  const [otp, setOtp] = useState('');
  const [verifyOtp, setVerifyOtp] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);

  const togglePassword = () => {
    setShowPas((prevShowPass) => !prevShowPass);
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = useForm({
    defaultValues: defaultValue(),
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (successSignUp && successSignIn) {
      setTimeout(() => {
        if (onChangeStep) {
          onChangeStep('1');
        } else {
          Router.push('/');
        }
      }, 1300);
    }
  }, [successSignUp, successSignIn]);

  const onVerify = async () => {
    setLoading(true);
    const phone = getValues('phone');
    const { err, data } = await WebApi().SMS.sendSms({
      phone: phone,
      smsTemplate: 'registration',
    });
    if (!err) {
      setVerifyOtp(true);
      toast.success('СМС отправлен ');
      if (remainingTime > 0) {
        return;
      }
      setIsTimerRunning(true);
      setRemainingTime(60);
    } else {
      setVerifyOtp(false);
      // Ошибка при регистрации с существующим номером
      if (err.message === 'Phone exists') {
        toast.error('Пользователь с таким номером уже существует!');
      } else {
        setLoading(false);
        toast.error(err?.message);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (isTimerRunning) {
      intervalId = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [isTimerRunning]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const { err } = await WebApi().User.signup({
        ...data,
        code: otp,
        redirect: false,
      });
      if (!err) {
        setSuccessSignUp(true);

        const signInResponse = await signIn('local-login', {
          ...data,
          redirect: false,
        });

        if (signInResponse && signInResponse.ok) {
          // Вход успешен
          setSuccessSignUp(true);
          setSuccessSignIn(true);
          toast.success('Вы успешно зарегистрировались!');
        } else {
          // Ошибка при входе
          setLoading(false);
          toast.error('Ошибка авторизации после регистрации');
        }
      } else {
        // Ошибка при регистрации
        setLoading(false);
        toast.error(err?.message);
      }
    } catch (err) {
      setLoading(false);
      toast.error('Ошибка регистрации');
    }
  };

  // Логика установки курсора в нужную позицию
  const inputRef = useRef<HTMLInputElement | null>(null);

  const getCursorPosition = (value: string): number => {
    // Защищаемся от неопределённого или пустого значения
    if (typeof value !== 'string' || value.trim() === '') {
      return 3; // Если строка пустая или не строка, ставим курсор в начало
    }

    const lastDigitIndex = value.search(/\d(?!.*\d)/);
    return lastDigitIndex !== -1 ? lastDigitIndex + 1 : 3;
  };

  const setCursor = (value: string): void => {
    if (inputRef.current) {
      const nextCursorPosition = Math.min(
        getCursorPosition(value),
        value.length
      );
      inputRef.current.setSelectionRange(
        nextCursorPosition,
        nextCursorPosition
      );
    }
  };

  const handleMaskedChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    onChange: (value: string) => void
  ) => {
    const { value } = event.target;
    if (typeof value === 'string') {
      onChange(value);
      setCursor(value);
    }
  };
  const handleFocusAndClick = (
    event:
      | React.FocusEvent<HTMLInputElement>
      | React.MouseEvent<HTMLInputElement>
  ) => {
    const { value } = event.target as HTMLInputElement;
    if (typeof value === 'string') {
      setCursor(value);
    }
  };

  return (
    <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
      <Card
        sx={{
          maxWidth: 550,
          padding: '96px',
          [theme.breakpoints.down('sm')]: { maxWidth: 300, padding: 3 },
        }}
      >
        <Typography
          variant="h3"
          sx={{
            textAlign: 'center',
            [theme.breakpoints.down('sm')]: { fontSize: 35 },
          }}
        >
          Регистрация
        </Typography>
        <Box mt={3} />
        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <InputMask
              mask={field.value ? '+7(\\999)999-99-99' : ''}
              value={field.value || ''}
              onChange={(event) => handleMaskedChange(event, field.onChange)}
              onBlur={field.onBlur}
              onFocus={handleFocusAndClick}
              onClick={handleFocusAndClick}
            >
              <TextField
                inputRef={inputRef}
                fullWidth
                size="small"
                label="Номер телефона"
                placeholder="Введите номер телефона"
                error={!!errors.phone}
                helperText={errors.phone?.message}
              />
            </InputMask>
          )}
        />
        <Box mt={3} />
        <FormControl sx={{ width: '100%' }} variant="outlined">
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                type={showPass ? 'text' : 'password'}
                {...field}
                size="small"
                fullWidth
                label="Пароль"
                placeholder="Введите пароль"
                error={!!errors.password}
                helperText={errors.password?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePassword}>
                        {showPass ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </FormControl>
        <Box mt={3} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <TextField
            type={'text'}
            fullWidth
            size="small"
            label="Код из СМС"
            placeholder="Введите код"
            sx={{ width: '60%' }}
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
          <Button
            onClick={onVerify}
            disabled={loading || remainingTime > 0}
            variant="contained"
            sx={{ width: '35%', lineHeight: 1.1 }}
          >
            {remainingTime > 0 ? `${remainingTime} сек` : 'Отправить СМС'}
          </Button>
        </Box>
        <Box mt={3} />
        <Box mb={3}>
          <Box sx={{ fontSize: 14, textAlign: 'center' }}>
            Регистрируясь, Вы принимаете условия{' '}
            <Link className={style.policy} href="/privacy/agreement">
              <Typography>Пользовательского соглашения</Typography>
            </Link>{' '}
            и{' '}
            <Link className={style.policy} href="/privacy/policy">
              <Typography>Политики конфиденциальности</Typography>
            </Link>
          </Box>
        </Box>
        <Button
          type="submit"
          disabled={!verifyOtp || loading}
          variant="contained"
          sx={{ width: '100%' }}
        >
          {loading ? <CircularProgress size={24} /> : 'Зарегистрироваться'}
        </Button>
        <Box mt={3} />
        <Box>
          <Typography>
            Уже есть аккаунт?
            {onChangeStep ? (
              <Button
                sx={{
                  background: '#fff',
                  p: 0,
                  m: 0,
                  color: 'rgb(0, 102, 255)',
                }}
                onClick={() => onChangeStep('1')}
              >
                Войти
              </Button>
            ) : (
              <Link className={style.link} href="/auth/signin">
                Войти
              </Link>
            )}
          </Typography>
        </Box>
        <Box mb={2}>
          {!handleClose ? (
            <Link className={style.minilink} href="/">
              Вернуться на главную
            </Link>
          ) : (
            <Button
              className={style.minilink}
              sx={{ background: '#fff', p: 0, fontSize: 16 }}
              onClick={() => handleClose()}
            >
              Вернуться на главную
            </Button>
          )}
        </Box>
      </Card>
    </form>
  );
};
